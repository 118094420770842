import { createSlice } from '@reduxjs/toolkit';

const menusSlice = createSlice({
  name: 'menus',
  initialState: {
    menus: [],
    counts: 0,
  },
  reducers: {
    fetchMainMenus(state, action) {
      state.menus = action.payload;
    },
  },
});

export const { fetchMainMenus } = menusSlice.actions;

export default menusSlice.reducer;