import { useRoutes } from 'react-router-dom';

import AdminLoginRoutes from './AdminLoginRoutes';
import AdminRoutes from './AdminRoutes';
import WebRoutes from './WebRoutes';


export default function ThemeRoutes() {
    // const user: any = useSelector((state) => state?.account?.user);
    // const userType = user?.type || null;
    const location = window.location.host;
    const pathArr = location.replace(/^\/|\/$/g, '').split('.').slice();

    let baseRoutes = [];

    if (pathArr.includes('app') ) {
        baseRoutes.push(AdminLoginRoutes, AdminRoutes);
    } else {
        baseRoutes.push(WebRoutes);
    }

    return useRoutes(baseRoutes);
}
