import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import LanguageSelector from 'LanguageSelector';

// login routing
const PagesFaqs = Loadable(lazy(() => import('views/pages/saas-pages/Faqs')));
const PagesPrivacyPolicy = Loadable(lazy(() => import('views/pages/saas-pages/PrivacyPolicy')));
const PageLanding = Loadable(lazy(() => import('views/pages/home')));
const AboutPage = Loadable(lazy(() => import('views/pages/about')));
const ContactPage = Loadable(lazy(() => import('views/pages/contact-us')));
const ServicePage = Loadable(lazy(() => import('views/pages/services')));
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const ViewCase = Loadable(lazy(() => import('views/admin/complaint/ViewCase')));


// ==============================|| AUTH ROUTING ||============================== //

const WebRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <LanguageSelector />
            <MinimalLayout />
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <PageLanding />
        },
        {
            path: '/home',
            element: <PageLanding />
        },
        {
            path: '/about',
            element: <AboutPage />
        },
        {
            path: '/services',
            element: <ServicePage />
        },
        {
            path: '/contact-us',
            element: <ContactPage />
        },
        {
            path: '/faqs',
            element: <PagesFaqs />
        },
        {
            path: '/privacy-policy',
            element: <PagesPrivacyPolicy />
        },
        {
            path: '/ticket/:id',
            element: <ViewCase/>
        },
        {
            path: '/*',
            element: <MaintenanceError />
        }
    ]
};

export default WebRoutes;
