import { createSlice } from '@reduxjs/toolkit';

const complaintCaseSlice = createSlice({
  name: 'complaintCase',
  initialState: {
    cases: [],
    casesCount: null,
    addUpdateDSASuccess: false
  },
  reducers: {
    fetchComplaintCases(state, action) { 
      state.cases = action.payload;
    },
    fetchComplaintCaseCount(state, action) { 
      state.casesCount = action.payload ? action.payload: 0;
    },

    addUpdateDSASuccess(state, action) { 
      state.addUpdateDSASuccess = action.payload;
    },

  },
});

export const {fetchComplaintCases, fetchComplaintCaseCount, addUpdateDSASuccess } = complaintCaseSlice.actions;

export default complaintCaseSlice.reducer;