import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';


const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

const Users = Loadable(lazy(() => import('views/admin/users')));
const AddUser = Loadable(lazy(() => import('views/admin/users/AddUser')));
const EditUser = Loadable(lazy(() => import('views/admin/users/EditUser')));
const RoleAndPermissions = Loadable(lazy(() => import('views/admin/roles')));
const AddRole = Loadable(lazy(() => import('views/admin/roles/AddRole')));
const EditRole = Loadable(lazy(() => import('views/admin/roles/EditRole')));
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));

const AddLang = Loadable(lazy(() => import('views/admin/lang/AddLang')));
const EditLangs = Loadable(lazy(() => import('views/admin/lang/EditLangs')));
const LangList = Loadable(lazy(() => import('views/admin/lang')));

const AddPage = Loadable(lazy(() => import('views/admin/staticPages/AddArticles')));
const EditPage = Loadable(lazy(() => import('views/admin/staticPages/AddArticles')));
const PagesList = Loadable(lazy(() => import('views/admin/staticPages')));

const HomePage = Loadable(lazy(() => import('views/admin/staticPages/home')));
const AboutPage = Loadable(lazy(() => import('views/admin/staticPages/about')));
const ServicePage = Loadable(lazy(() => import('views/admin/staticPages/services')));
const ContactPage = Loadable(lazy(() => import('views/admin/staticPages/contact')));

const AddArticle = Loadable(lazy(() => import('views/admin/knowledgeBase/AddArticles')));
const EditArticle = Loadable(lazy(() => import('views/admin/knowledgeBase/EditArticles')));
const KnowledgeBase = Loadable(lazy(() => import('views/admin/knowledgeBase')));

const Complaint = Loadable(lazy(() => import('views/admin/complaint/index')));
const CreateCase = Loadable(lazy(() => import('views/admin/complaint/CreateCase')));
const ViewCase = Loadable(lazy(() => import('views/admin/complaint/ViewCase')));

const SubProduct = Loadable(lazy(() => import('views/admin/product/subProducts')));
const AddSubProduct = Loadable(lazy(() => import('views/admin/product/subProducts/AddProduct')));
const EditSubProduct = Loadable(lazy(() => import('views/admin/product/subProducts/EditProduct')));
const ViewStepsList = Loadable(lazy(() => import('views/admin/product/flow/steps')));
const ViewFlowList = Loadable(lazy(() => import('views/admin/product/flow')));

const Product = Loadable(lazy(() => import('views/admin/product/product')));
const Questions1 = Loadable(lazy(() => import('views/admin/product/subProducts/Questions')));
const EditProductQuestions = Loadable(lazy(() => import('views/admin/product/subProducts/EditProductQuestions')));
const AddProduct = Loadable(lazy(() => import('views/admin/product/product/AddProductTypes')));
const EditProduct = Loadable(lazy(() => import('views/admin/product/product/EditProductTypes')));

const ProductTypes = Loadable(lazy(() => import('views/admin/product/productTypes')));
const AddProductTypes = Loadable(lazy(() => import('views/admin/product/productTypes/AddProductTypes')));
const EditProductTypes = Loadable(lazy(() => import('views/admin/product/productTypes/EditProductTypes')));

const AddUsesTerms = Loadable(lazy(() => import('views/admin/mailTemplates/AddMailTemplate')));
const ViewUsesTerms = Loadable(lazy(() => import('views/admin/mailTemplates/')));
const EditMailTemplate = Loadable(lazy(() => import('views/admin/mailTemplates/EditMailTemplate')));

const FooterLink = Loadable(lazy(() => import('views/admin/menus/FooterMenu')));

const Keywords = Loadable(lazy(() => import('views/admin/keywordsSetting')));
const AddKeyword = Loadable(lazy(() => import('views/admin/keywordsSetting/AddKeyword')));
const EditKeyword = Loadable(lazy(() => import('views/admin/keywordsSetting/EditKeyword')));

const Posts = Loadable(lazy(() => import('views/admin/helpCenter')));
const AddPost = Loadable(lazy(() => import('views/admin/helpCenter/AddArticles')));
const EditPost = Loadable(lazy(() => import('views/admin/helpCenter/AddArticles')));
const AddCategory = Loadable(lazy(() => import('views/admin/helpCenter/category/AddCategory')));
const Category = Loadable(lazy(() => import('views/admin/helpCenter/category')));

const CustomFields = Loadable(lazy(() => import('views/admin/product/customFields')));
const Questions = Loadable(lazy(() => import('views/admin/product/questions')));
const CustomFieldsLanguages = Loadable(lazy(() => import('views/admin/product/customFields/langs')));
const QuestionsLanguages = Loadable(lazy(() => import('views/admin/product/questions/langs')));


const FlowBuilders = Loadable(lazy(() => import('views/admin/product/flowBuilder')));
const AddFlowBuilder = Loadable(lazy(() => import('views/admin/product/flowBuilder/AddFlowBuilder')));
const EditFlowBuilder = Loadable(lazy(() => import('views/admin/product/flowBuilder/EditFlowBuilder')));
const EditFlowLanguages = Loadable(lazy(() => import('views/admin/product/flow/steps/langs')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />,           
        },
        {
            path: '/dashboard/analytics',
            element: <DashboardAnalytics />
        },
        // {
        //     path: '/menus/main-menu',
        //     element: <HeaderLink/>
        // },
        {
            path: '/menus/footer-menu',
            element: <FooterLink/>
        },
        {
            path: '/lang',
            element: <LangList/>
        },
        {
            path: '/lang/add',
            element: <AddLang/>
        },
        {
            path: '/lang/edit/:id',
            element: <EditLangs/>
        },
        {
            path: '/posts/list',
            element: <Posts/>
        },
        {
            path: '/posts/create',
            element: <AddPost/>
        },
        {
            path: '/posts/edit/:id',
            element: <EditPost/>
        },
        {
            path: '/posts/category/add',
            element: <AddCategory/>
        },
        {
            path: '/posts/category/edit/:id',
            element: <AddCategory/>
        },
        {
            path: '/posts/category',
            element: <Category/>
        },
        {
            path: '/pages',
            element: <PagesList/>
        },
        {
            path: '/pages/add',
            element: <AddPage/>
        },
        {
            path: '/pages/static/home',
            element: <HomePage/>
        },
        {
            path: '/pages/static/about',
            element: <AboutPage/>
        },
        {
            path: '/pages/static/contact',
            element: <ContactPage/>
        },
        {
            path: '/pages/static/services',
            element: <ServicePage/>
        },
        {
            path: '/pages/edit/:id',
            element: <EditPage/>
        },
        {
            path: '/mail-templates/list',
            element: <ViewUsesTerms/>
        },
        {
            path: '/mail-templates/create',
            element: <AddUsesTerms />
        },
        {
            path: '/mail-templates/edit-mail-template/:id',
            element: <EditMailTemplate/>
        },
        {
            path: '/complaint/list',
            element: <Complaint/>
        },
        {
            path: '/complaint/create',
            element: <CreateCase />
        },
        {
            path: '/complaint/view/:id',
            element: <ViewCase/>
        },
        {
            path: '/product-types/list',
            element: <ProductTypes />
        },
        {
            path: '/product-types/add',
            element: <AddProductTypes />
        },
        {
            path: '/product-types/edit/:id',
            element: <EditProductTypes />
        },
        {
            path: '/sub-product/list',
            element: <SubProduct />
        },
        {
            path: '/sub-product/add',
            element: <AddSubProduct />
        },
        {
            path: '/sub-product/edit/:id',
            element: <EditSubProduct />
        },
        {
            path: '/flow-builder/list',
            element: <FlowBuilders />
        },
        {
            path: '/flow-builder/add',
            element: <AddFlowBuilder />
        },
        {
            path: '/flow-builder/edit/:id',
            element: <EditFlowBuilder />
        },
        {
            path: '/flow-builder/flow/:id',
            element: <ViewStepsList />
        },
        {
            path: '/flow-builder/flow/view/:id',
            element: <ViewFlowList />
        },
        {
            path: '/flow-builder/custom-fields',
            element: <CustomFields />
        },
        {
            path: '/flow-builder/questions',
            element: <Questions/>
        },
        {
            path: '/flow-builder/questions/language/:id',
            element: <QuestionsLanguages/>
        },
        // {
        //     path: '/flow-builder/questions/edit/:id',
        //     element: <EditProductQuestions/>
        // },
        {
            path: '/flow-builder/flow/step/language/:id',
            element: <EditFlowLanguages/>
        },
        {
            path: '/flow-builder/custom-fields/language/:id',
            element: <CustomFieldsLanguages/>
        },
        {
            path: '/product/list',
            element: <Product />
        },
        {
            path: '/product/add',
            element: <AddProduct />
        },
        {
            path: '/product/edit/:id',
            element: <EditProduct/>
        },
        {
            path: '/keywords/create',
            element: <AddKeyword/>
        },
        {
            path: '/keywords/list',
            element: <Keywords/>
        },
        {
            path: '/keywords/edit/:id',
            element: <EditKeyword/>
        },
        {
            path: '/faq/list',
            element: <KnowledgeBase/>
        },
        {
            path: '/faq/add',
            element: <AddArticle />
        },
        {
            path: '/faq/edit/:id',
            element: <EditArticle/>
        },
        {
            path: '/user/list',
            element: <Users />
        },
        {
            path: '/user/add',
            element: <AddUser />
        },
        {
            path: '/user/edit/:id',
            element: <EditUser />
        },
        {
            path: '/user/role/list',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role/add',
            element: <AddRole/>
        }
        ,
        {
            path: '/user/role/edit/:id',
            element: <EditRole/>
        },
        {
            path: '/*',
            element: <MaintenanceError/>
        }


    ],

};

export default AdminRoutes;
