import { createSlice } from '@reduxjs/toolkit';

const productMasterSlice = createSlice({
  name: 'subProduct',
  initialState: {
    subProducts: [],
    counts: 0,
    editProduct: {},
    addUpdateProductSuccess: false,
    csvImportSuccess: false,
  },
  reducers: {
    fetchProductMasters(state, action) { 
      state.subProducts = action.payload;
    },
    productMastersCount(state, action) { 
      state.counts = action.payload ? action.payload: 0;
    },
    addUpdateProductSuccess(state, action) { 
      state.addUpdateProductSuccess = action.payload;
    },
    fetchEditProduct(state, action) { 
      state.editProduct = action.payload;
    },
    csvImportSuccess(state, action) { 
      state.csvImportSuccess = action.payload;
    },
  },
});

export const { fetchProductMasters, fetchEditProduct, productMastersCount , addUpdateProductSuccess, csvImportSuccess} = productMasterSlice.actions;

export default productMasterSlice.reducer;