import { createSlice } from '@reduxjs/toolkit';

const translationsSlice = createSlice({
  name: 'translations',
  initialState: {
    langs: [],
    translations: {},
    publicTranslations: [],
    currentProductTranslations: {},
    success: false
  },
  reducers: {
    fetchLangsList(state, action) { 
      state.langs = action.payload;
    },
    fetchTranslations(state, action) { 
      state.translations = action.payload;
    },
    fetchPublicTranslations(state, action) { 
      state.publicTranslations = action.payload;
    },
    fetchCurrentProductTranslations(state, action) { 
      state.currentProductTranslations = action.payload;
    },
    fetchUpdateSuccess(state, action) { 
      state.success = action.payload;
    }
  },
});

export const {fetchLangsList, fetchUpdateSuccess, fetchCurrentProductTranslations, fetchTranslations, fetchPublicTranslations} = translationsSlice.actions;

export default translationsSlice.reducer;