// context/LanguageContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const LanguageContext = createContext({
    isLanguageSelectorOpen: false,
    closeLanguageSelector: () => { },
    openLanguageSelector: () => { },
    setLangMethod: (lang: string) => { },
    language: 'en',
});

export const LanguageProvider = ({ children }: any) => {
    const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState(false);
    const [language, setLanguage] = useState('');

    const openLanguageSelector = () => {
        setIsLanguageSelectorOpen(true);
    };

    const setLangMethod = (lang: string) => {
        localStorage.setItem('locale', lang ? lang : 'en');
        setLanguage(lang);
    };

    const closeLanguageSelector = () => {
        setIsLanguageSelectorOpen(false);
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('locale');
        if (!savedLanguage) {
            openLanguageSelector(); // Automatically open modal if no locale found
        } else {
            setLanguage(savedLanguage);
        }
    }, [language]);

    return (
        <LanguageContext.Provider value={{ isLanguageSelectorOpen, openLanguageSelector, closeLanguageSelector, language, setLangMethod }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    return useContext(LanguageContext);
};
