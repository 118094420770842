import { createSlice } from '@reduxjs/toolkit';

const productTypesSlice = createSlice({
  name: 'productTypes',
  initialState: {
    productTypes: [],
    counts: 0,
    editProductType: {},
    addUpdateProductTypeSuccess: false,
  },
  reducers: {
    fetchProductTypes(state, action) { 
      state.productTypes = action.payload;
    },
    productTypesCount(state, action) { 
      state.counts = action.payload ? action.payload: 0;
    },
    addUpdateProductTypeSuccess(state, action) { 
      state.addUpdateProductTypeSuccess = action.payload;
    },
    fetchEditProductType(state, action) { 
      state.editProductType = action.payload;
    },
  },
});

export const { fetchProductTypes, fetchEditProductType, productTypesCount , addUpdateProductTypeSuccess} = productTypesSlice.actions;

export default productTypesSlice.reducer;