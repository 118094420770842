import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: [],
    counts: 0,
    editProduct: {},
    addUpdateProductTypeSuccess: false,
    icons: [],
  },
  reducers: {
    fetchProduct(state, action) { 
      state.products = action.payload;
    },
    fetchIconsOfProducts(state, action) { 
      state.icons = action.payload;
    },
    productCount(state, action) { 
      state.counts = action.payload ? action.payload: 0;
    },
    addUpdateProductSuccess(state, action) { 
      state.addUpdateProductTypeSuccess = action.payload;
    },
    fetchEditProductType(state, action) { 
      state.editProduct = action.payload;
    },
  },
});

export const { fetchProduct, fetchIconsOfProducts, fetchEditProductType, productCount , addUpdateProductSuccess} = productSlice.actions;

export default productSlice.reducer;