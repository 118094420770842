import React, { useState, useEffect } from 'react';
import { Modal, Button, CardContent, Typography, Box } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'contexts/LanguageContext';
import languagesData from 'utils/languages.json';


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export const useTranslate = () => {
    const { t } = useTranslation();
    const translate = (key: string, defaultValue?: string) => {
        return t(key, { defaultValue: defaultValue || key });
    };
    return { translate };
};


const LanguageSelector = () => {
    const { translate } = useTranslate();

    const { isLanguageSelectorOpen, closeLanguageSelector, language, setLangMethod } = useLanguage();
    const [open, setOpen] = useState<boolean>(language === '');
    const [languages, setLanguages] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [modalStyle] = React.useState(getModalStyle);

    const fetchLanguages = async () => {
        try {
            if (languagesData?.languages) {
                setLanguages(languagesData?.languages);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    useEffect(() => {
        fetchLanguages();
    }, []);


    const changeLanguage = async (lang: string) => {
        setLangMethod(lang ? lang : 'en');
        setOpen(false);
        closeLanguageSelector();
    };

    return (
        <Modal sx={{}} open={open || isLanguageSelectorOpen} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <MainCard
                style={modalStyle}
                sx={{
                    position: 'absolute',
                    width: { xs: '90vw', lg: '70vw' },
                    top: '45%',
                    left: '48%',
                    transform: 'translate(-50%, -50%)',
                    '&:focus-visible': {
                        outline: 'none'
                    }
                }}
                title={translate("Select Language")}
                content={false}

            >
                <CardContent sx={{ position: 'relative', paddingTop: '10px', paddingBottom: '10px' }}>
                    <Box style={{ padding: '20px', textAlign: 'center', }}>
                        <Typography variant='h4' component="h4">{translate("Select Your Preferred Language")}</Typography>
                        {loading ? (
                            <p>{translate("Loading languages", "Loading languages...")}</p>
                        ) : (
                            <>{languages.map((lang: any) => (
                                <Button
                                    className={language === lang?.code ? 'selected' : ''}
                                    key={lang?.code}
                                    onClick={() => changeLanguage(lang?.code)}
                                    sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '80px',  // Set uniform width
                                        height: '80px', // Set uniform height
                                        padding: '20px',
                                        margin: '10px',
                                        border: '2px solid #f5f5f5',
                                        backgroundColor: '#f5f5f5',
                                        color: '#333',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        transition: 'transform 0.3s ease, background-color 0.3s ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            //transform: 'scale(1.1)',
                                            backgroundColor: '#ffffff',
                                            border: '2px solid #2196f3'
                                        },
                                        '&.selected': {
                                            //transform: 'scale(1.1)',
                                            backgroundColor: lang?.code === language ? '#ffffff' : 'inherit',
                                            border: lang?.code === language ? '2px solid #2196f3' : 'inherit'
                                        },
                                    }}>
                                    <div>{lang?.icon}</div>
                                    <div style={{ fontSize: '12px' }}>{lang?.name}</div>
                                    <div style={{ fontSize: '12px' }}>({lang?.altName})</div>
                                </Button>
                            ))}</>
                        )}
                    </Box>
                </CardContent>
            </MainCard>
        </Modal>
    );
};

export default LanguageSelector;
