import get from "lodash/get";


export function getUser(state: any) {
    let obj = get(state, ["dsa", "user"]);
    if (obj) {
        return obj;
    }
    return {};
}

export function getAccountSmtpSelector(state: any) {
    return get(state, ["dsa", "accountSettings", 'smtp']) ?? {};
}

export function getAccountSmtpSavedSelector(state: any) {
    return get(state, ["dsa", "accountSettings", 'smtpSaved']) ?? {};
}

export function getCurrentUserRole(state: any) {
    let obj = get(state, ["dsa", "user", "role", "permissions"]);
    if (obj) {
        const roles: any = [];
        obj.forEach((p: any) => {
            if (p && p.name) {
                const perm: any = [];
                p.items.forEach((item: any) => {
                    if (item && item.name && item.checked) {
                        perm.push(item.name);
                    }
                });
                roles.push({ [p.name]: perm })
            }
        })
        return roles;
    }
    return [];
}

export function isAuthenticated(state: any) {
    let isAuth = get(state, ["dsa", "auth", "isAuth"]);
    if (isAuth) {
        return isAuth;
    }
    return false;
}

export function fetchUserProfileSelector(state: any) {
    let user = get(state, ["dsa", "profile", "user"]);
    if (user && Object.keys(user).length > 0) {
        return user;
    }
    return false;
}
export function fetchUserProfileAgreementSelector(state: any) {
    let user = get(state, ["dsa", "profile", "profileAgreement"]);
    if (user && Object.keys(user).length > 0) {
        return user;
    }
    return false;
}

export function fetchUserVerifyMessage(state: any) {
    let users = get(state, ["dsa", "users", "userMetaVerifyCompleted"]) || null;
    return users;
}

export function fetchUsers(state: any) {
    let users = get(state, ["dsa", "users", "users"]) || [];
    return users;
}


export function usersCount(state: any) {
    let usersCount = get(state, ["dsa", "users", "usersCount"]);
    if (usersCount) {
        return usersCount;
    }
    return null;
}

export function addUpdateSuccess(state: any) {
    let addUpdateSuccess = get(state, ["dsa", "users", "addUpdateSuccess"]);
    if (addUpdateSuccess) {
        return addUpdateSuccess;
    }
    return false;
}

export function fetchRolesSelector(state: any) {
    let roles = get(state, ["dsa", "roles", "roles"]) || [];
    return roles;
}

export function roleCountSelector(state: any) {
    let counts = get(state, ["dsa", "roles", "count"]) || null;
    return counts;
}
//rolePermissions

export function rolePermissions(state: any) {
    let permissions = get(state, ["dsa", "roles", "permissions", "permissions"]) || {};
    return permissions;
}

export function addRoleSuccess(state: any) {

    let success = get(state, ["dsa", "roles", "success"]);
    if (success) {
        return success;
    }
    return false;
}

export function sidebarCollapseSelector(state: any) {
    let data = get(state, ["sidebarCollapse"]);
    if (data) {
        return data;
    }
    return null;
}

export function getCurrentUserProfile(state: any) {
    let profile = get(state, ["account", 'user']);
    if (profile) {
        return profile;
    }
    return [];
}

export function fetchUserSelector(state: any) {
    let fetchDSACompanyProfile = get(state, ["dsa", "users", "userProfile"]) || [];
    return fetchDSACompanyProfile;
}


export function getPreviewImageSelector(state: any) {
    let applicationProfile = get(state, ["dsa", "application", "documentPreview"]) || null;
    return applicationProfile;
}

export function isToggleThemeCustomizeSelector(state: any) {
    let channelVariables = get(state, ["dsa", "accountSettings", "isToggleThemeCustomize"]) || false;
    return channelVariables;
}

export function fetchAccountSettingsSelector(state: any) {
    let channelVariables = get(state, ["dsa", "accountSettings"]) || false;
    return channelVariables;
}
