import { memo, useEffect, useState } from 'react';
import { useSelector } from 'store';
import { fetchAccountSettingsSelector } from 'store/state/dsaSelector';
import winpro from 'assets/images/mehuna.svg';
import LazyImage from 'views/components/LazyImage';

const Logo = () => {
    const accountSettings = useSelector(fetchAccountSettingsSelector);
    const [logoImage, setLogoImage] = useState<string>(winpro);

    useEffect(() => {
        if (accountSettings?.logoImage) {
            setLogoImage(accountSettings.logoImage);
        }
    }, [accountSettings]);

    return (
        <LazyImage
            src={logoImage}
            alt={accountSettings?.alt || "Mehuna"}
            width="200"
            height="50"
            style={{ objectFit: 'contain' }}
        />
    );
};

export default memo(Logo);
