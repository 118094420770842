import { createSlice } from '@reduxjs/toolkit';

const mailTemplateSlice = createSlice({
  name: 'mailTemplates',
  initialState: {
    templates: [],
    templatesCount: null,
    editTemplate: null,
    success: false,
  },
  reducers: {
    fetchTemplates(state, action) {
      state.templates = action.payload;
    },
    editTemplate(state, action) {
      state.editTemplate = action.payload;
    },
   
    setTemplateCount(state, action) {
      state.templatesCount = action.payload ? action.payload : 0;
    },
    setTemplateSuccess(state, action) {
      state.success = action.payload;
    }
  },
});

export const {
  fetchTemplates,
  setTemplateCount,
  setTemplateSuccess, editTemplate
} = mailTemplateSlice.actions;

export default mailTemplateSlice.reducer;
