import { createSlice } from '@reduxjs/toolkit';

const keywordsSlice = createSlice({
  name: 'keywords',
  initialState: {
    keywords: [],
    counts: 0,
    editKeyword: {},
    addUpdateKeywordSuccess: false,
  },
  reducers: {
    fetchKeywords(state, action) { 
      state.keywords = action.payload;
    },
    keywordCount(state, action) { 
      state.counts = action.payload ? action.payload: 0;
    },
    addUpdateKeywordSuccess(state, action) { 
      state.addUpdateKeywordSuccess = action.payload;
    },
    fetchEditKeyword(state, action) { 
      state.editKeyword = action.payload;
    },
  },
});

export const { fetchKeywords, fetchEditKeyword , keywordCount, addUpdateKeywordSuccess} = keywordsSlice.actions;

export default keywordsSlice.reducer;