import axios from 'axios';
import { accountLogout } from '../store/state/data-slices/accountSlice';
import { dispatch } from 'store';

const axiosServices = axios.create({ 
    timeout: 60000, maxBodyLength: Infinity,
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000',
    withCredentials: true
});

axiosServices.interceptors.request.use(
    function (config) {
        // Check if the request contains a file
        if (config.data instanceof FormData && config.data.get('file')) {
            // Set content type for file uploads
            config.headers['Content-Type'] = 'multipart/form-data';
        } else {
            // Set content type for other requests
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error && error?.response?.status === 401) dispatch(accountLogout({}));
        return Promise.reject((error.response && error.response.data) || 'Wrong Services')
    }
);

export default axiosServices;
