import i18n from 'i18next'; // Correct import statement
import { initReactI18next } from 'react-i18next';
import axios from './axios';

// Function to fetch translations from the server
const fetchTranslations = async (lang: string) => {
  try {
    const response = await axios({url: `/translations/public`, method: 'post', data: {lang: lang ?? 'en',}});
    if (response?.data) {
      return response?.data;
    }
    return {};
  } catch (error) {
    return {};
  }
};


const initializeI18n = async () => {
  const storedLang = localStorage.getItem('locale') || 'en';
  const translations = await fetchTranslations(storedLang);

  i18n
    .use(initReactI18next)
    .init({
      resources: {
        [storedLang]: {
          translation: translations,
        },
      },
      lng: storedLang,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
    });

  return i18n;
};

export default initializeI18n;
