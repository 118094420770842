import { createSlice } from '@reduxjs/toolkit';

const flowBuilderSlice = createSlice({
  name: 'flowBuilder',
  initialState: {
    flowBuilders: [],
    products: [],
    subProducts: [],
    counts: 0,
    editFlowBuilder: {},
    addUpdateFlowBuilderSuccess: false,
    productFlow: {},
    editProductFlow: {},
    productCurrentFlow: {},
    productFlowQuestionsList: [],
    productFlowQuestionsCount: 0,
    productFlowQuestionsEdit: {}
  },
  reducers: {
    fetchFlowBuilders(state, action) { 
      state.flowBuilders = action.payload;
    },
    flowBuildersCount(state, action) { 
      state.counts = action.payload ? action.payload: 0;
    },
    addUpdateFlowBuilderSuccess(state, action) { 
      state.addUpdateFlowBuilderSuccess = action.payload;
    },
    flowBuilderProducts(state, action) { 
      state.products = action.payload;
    },
    flowBuilderSubProducts(state, action) { 
      state.subProducts = action.payload;
    },
    fetchEditFlowBuilder(state, action) { 
      state.editFlowBuilder = action.payload;
    },
    fetchProductFlowQuestionEdit(state, action) { 
      state.productFlowQuestionsEdit = action.payload;
    },
    fetchProductFlowQuestionsList(state, action) { 
      state.productFlowQuestionsList = action.payload;
    },
    fetchProductFlowQuestionsCount(state, action) { 
      state.productFlowQuestionsCount = action.payload;
    },
    fetchProductFlow(state, action) { 
      state.productFlow = action.payload;
    },
    fetchProductCurrentFlow(state, action) { 
      state.productCurrentFlow = action.payload;
    },
  },
});

export const { fetchFlowBuilders, 
  flowBuildersCount, 
  addUpdateFlowBuilderSuccess, 
  fetchEditFlowBuilder,
  flowBuilderProducts,
  flowBuilderSubProducts,
  fetchProductCurrentFlow, 
  fetchProductFlowQuestionEdit, 
  fetchProductFlowQuestionsList, 
  fetchProductFlowQuestionsCount, 
  fetchProductFlow, 
} = flowBuilderSlice.actions;

export default flowBuilderSlice.reducer;