import { createSlice } from '@reduxjs/toolkit';

const subProductStepsSlice = createSlice({
  name: 'subProductSteps',
  initialState: {
    steps: [],
    copySteps: [],
    counts: 0,
    editStep: {},
    addUpdateStepSuccess: false,
  },
  reducers: {
    fetchSubProductSteps(state, action) { 
      state.steps = action.payload;
    },
    fetchSubProductCopySteps(state, action) { 
      state.copySteps = action.payload;
    },
    subProductStepsCount(state, action) { 
      state.counts = action.payload ? action.payload: 0;
    },
    addUpdateStepSuccess(state, action) { 
      state.addUpdateStepSuccess = action.payload;
    },
    fetchEditStep(state, action) { 
      state.editStep = action.payload;
    },
  },
});

export const { fetchSubProductSteps, fetchSubProductCopySteps, subProductStepsCount, fetchEditStep , addUpdateStepSuccess} = subProductStepsSlice.actions;

export default subProductStepsSlice.reducer;