import { LanguageProvider } from 'contexts/LanguageContext';
import AppWeb from 'AppWeb';

const AppLang = () => {
    return (<LanguageProvider>
        <AppWeb />
    </LanguageProvider>
    );
};

export default AppLang;
