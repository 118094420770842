import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'public',
  initialState: {
    products: [],
    subProducts: [],
    grievances: [],
    banks: [],
    flowBuilder: {},
    flowBuilderAllSteps: [],
    totalSteps: 0,
    icons: []
  },
  reducers: {
    fetchAllPublicProducts(state, action) { 
      state.products = action.payload;
    },
    fetchBanks(state, action) { 
      state.banks = action.payload;
    },
    fetchFlowBuilderSteps(state, action) { 
      state.flowBuilder = action.payload;
    },
    fetchFlowBuilderAllSteps(state, action) { 
      state.flowBuilderAllSteps = action.payload;
    },
    fetchAllPublicSubProducts(state, action) { 
      state.subProducts = action.payload;
    },
    fetchAllPublicGrievance(state, action) { 
      state.grievances = action.payload;
    },
    fetchPublicStepsCount(state, action) { 
      state.totalSteps = action.payload;
    },
    fetchIconsOfPublicProducts(state, action) { 
      state.icons = action.payload;
    },
  },
});

export const {fetchAllPublicProducts, fetchBanks, fetchFlowBuilderAllSteps, fetchPublicStepsCount, fetchFlowBuilderSteps, fetchIconsOfPublicProducts, fetchAllPublicSubProducts, fetchAllPublicGrievance} = productSlice.actions;

export default productSlice.reducer;