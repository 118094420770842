import dashboard from './dashboard';
import application from './application';
import { NavItemType } from 'types';

const menu = [];
menu.push(dashboard, application);
const menuItems: { items: NavItemType[] } = {
    items: menu
};
export default menuItems;
